/** @jsx jsx */
import { jsx } from "theme-ui";

import Container from "@components/Container";
import { rem } from "@src/theme";
import Layout from "@src/components/Layout";
import SEO from "@components/seo";

interface Props {
  location: any;
  pageTitle: string;
  pageDescription?: string;
  metaDescription?: string;
  children: React.ReactChild;
}

export default ({
  location,
  pageTitle,
  pageDescription,
  metaDescription,
  children,
}: Props) => {
  return (
    <Layout>
      <SEO
        defaultTitle={pageTitle}
        metadata={{
          metaDataDescription: metaDescription || pageDescription,
        }}
        location={location}
      />
      <div
        sx={{
          pt: [rem(20), rem(40), rem(60)],
        }}
      >
        <Container>
          <section
            sx={{
              pb: rem(40),
              pt: rem(40),
            }}
          >
            <div>
              <h1
                sx={{
                  bg: "tomato",
                  py: rem(10),
                  px: rem(15),
                  display: "inline-block",
                  fontSize: rem(50),
                }}
              >
                {pageTitle}
              </h1>
              {pageDescription && (
                <p
                  sx={{
                    maxWidth: rem(800),
                    fontSize: rem(25),
                  }}
                >
                  {pageDescription}
                </p>
              )}
            </div>
          </section>
        </Container>
        {children}
      </div>
    </Layout>
  );
};
