/** @jsx jsx */
import { jsx } from "theme-ui";
import { Grid, Cell } from "@components/Grid";
import { htmlBodyStyles } from "@src/theme";
import Image from "@components/Image";
import { rem } from "@src/theme";
interface ComponentProps {
  alt?: string;
  src?: string;
  body?: React.ReactChild;
  sectionHeading?: string;
}

const ImageTextBlock = ({ alt, src, body }: ComponentProps) => (
  <Grid>
    <Cell gridColumn={["span 12", null, "3 / span 6"]}>
      {src && <Image src={src} alt={alt} />}
    </Cell>
    <Cell gridColumn={["span 12", null, "3 / span 6"]}>
      {body && (
        <div
          sx={{
            ...htmlBodyStyles,
            mb: rem(60),
          }}
        >
          {body}
        </div>
      )}
    </Cell>
  </Grid>
);

export default ImageTextBlock;
