/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import Container from "@components/Container";
interface ComponentProps {
  text: string;
  author: string;
}

const Testimonial = ({ text, author }: ComponentProps) => {
  return (
    <section
      sx={{
        my: rem(80),
        bg: "white",
        py: rem(40),
        color: "black",
        p: {
          maxWidth: ["90%", null, "75%"],
          mx: "auto",
        },
      }}
    >
      <Container>
        <p>{text}</p>
        <p
          sx={{
            fontSize: rem(25),
            mt: rem(25),
          }}
          dangerouslySetInnerHTML={{ __html: author }}
        />
      </Container>
    </section>
  );
};
export default Testimonial;
