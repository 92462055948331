/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import Testimonial from "@src/components/Testimonial";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content pageTitle="Comics teaching" location={location}>
    <div>
      <Container>
        <div>
          <ImageTextBlock
            alt="Bernard sitting on table in front of sketches with hands up"
            src="/images/bernard-school.jpg"
            body={
              <div>
                <p>
                  Bernard has been delivering comics workshops, information
                  sessions and visual performed stories at primary school,
                  secondary schools, libraries, and universities for more than
                  twenty years’. He is an energetic, engaged and enthusiastic
                  presenter.
                </p>

                <h2>Schools</h2>
                <p>
                  For 20 years Bernard has been presenting comics workshops and
                  talks for primary, secondary and tertiary students. He is also
                  a popular speaker at libraries, talking about comics to groups
                  of all ages.
                </p>
                <p>Sessions can take the form of:</p>

                <ul>
                  <li>
                    — workshops: ‘Making a Comic’, during which the participants
                    produce a comic booklet;
                  </li>
                  <li>
                    — information sessions: ie ‘The Story of Comics’ which is a
                    talk illustrated with slides and live drawing, describing
                    the evolution of the artform;
                  </li>
                  <li>
                    — an introduction to the analysis of comics texts for
                    VCE/HSC listed graphic novels including Maus and Persepolis;
                  </li>
                  <li>
                    — tailored presentations addressing specific aspects of
                    English or Art curricula; an artist’s talk, in which Bernard
                    describes his comic book making process and discusses other
                    approaches to making comic books.
                  </li>
                </ul>
              </div>
            }
          />
        </div>
      </Container>

      <Testimonial
        text="“Bernard presented three online workshops to groups of Year 8 students at Strathcona
    GGS. The purpose of the day was to give the students a fun and creative outlet after a
    term of online classes. The planning process with Bernard was easy and the day went
    well. Bernard encouraged the students to create self-portraits and then talked them
    through the process of creating little zines, which they could then do on their own. He
    demonstrated techniques and gave them a story structure.  All the students participated
    and the supervising teachers really enjoyed it too. Bernard’s online presentation was
    engaging and in an hour everyone created a zine. The feedback from students and
    teachers was positive. One of the students uploaded her zine with the caption, “This was
    so fun.”"
        author="- Penny Roberts, </br>Director of Library and Research at Strathcona Girls Grammar School"
      />

      <CTABanner
        lines={[
          <div>
            <Link to="/contact">Contact Booked Out</Link> to book Bernard for a
            comics session at your school, university, book group or library
          </div>,
          <Link to="/comics-performance">Comics Performances</Link>,
          <Link to="/comics-tutoring">Comics Tutoring</Link>,
        ]}
      />
    </div>
  </Content>
);
