/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import Container from "@components/Container";
interface ComponentProps {
  lines: React.ReactChild[];
}

const CTABanner = ({ lines }: ComponentProps) => {
  return (
    <Container>
      <section
        sx={{
          bg: "tomato",
          py: rem(40),
          px: rem(35),
          my: rem(80),
          color: "black",
          border: "6px solid",
          borderColor: "black",
          fontSize: [rem(20), null, rem(30)],
        }}
      >
        {lines.map((l) => (
          <div
            sx={{
              display: "flex",
              mb: rem(12),
              "> span": {
                display: "inline-block",
                mr: rem(10),
              },
            }}
          >
            <span>—</span> {l}
          </div>
        ))}
      </section>
    </Container>
  );
};
export default CTABanner;
